import styled from "styled-components"

import {
  TExplorationCardEventTypes,
  usePostOnboardingExplorationCardClickedTrackingEvent,
} from "src/data/analytics/queries/OnboardingExplorationCardClickedAnalyticsQueries"
import { TPartialLocation } from "src/router/routeTypes"
import { mColors } from "src/ui/colors"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export type TExplorationCardProps = {
  icon: React.ReactNode
  title: string
  content: string
  linkText: string
  eventType: TExplorationCardEventTypes
  hidden?: boolean
} & (
  | {
      external: true
      to: string
    }
  | {
      external: false
      to: TPartialLocation
    }
)

export function DashboardExplorationCard({
  icon,
  title,
  content,
  linkText,
  to,
  external,
  eventType,
}: TExplorationCardProps) {
  const { mutate } = usePostOnboardingExplorationCardClickedTrackingEvent()

  return (
    <StyledCard>
      <div>
        <TopWrapper>
          <StyledIconBox>{icon}</StyledIconBox>
          <MText variant="subtitle">{title}</MText>
        </TopWrapper>
        <MText as="p" marginBottom={spacing.S}>
          {content}
        </MText>
      </div>
      {external && typeof to === "string" ? (
        <ExternalLink
          href={to}
          beforeNav={() =>
            mutate({
              type: eventType,
            })
          }
        >
          {linkText}
        </ExternalLink>
      ) : (
        <InternalLink
          to={to}
          beforeNav={() => {
            mutate({
              type: eventType,
            })
          }}
        >
          {linkText}
        </InternalLink>
      )}
    </StyledCard>
  )
}

const StyledCard = styled(MCard)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: none;
  background-color: ${mColors.accentPrimaryLight};
`

const StyledIconBox = styled.div`
  width: 24px;
`

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.S};
  margin-bottom: ${spacing.S};
`
